import SelectOption from './selectOption';
import { TFunction } from 'i18next';

const roleList = (t: TFunction): SelectOption[] => [
    { value: 'Admin', label: `${t('Admin')}` },
    { value: 'User', label: `${t('USER')}` },
    { value: 'System_Admin', label: `${t('IS_SYSTEM_ADMIN')}` }
];

export default roleList;
