import { IconList, IconUser } from '@tabler/icons';
import HubIcon from '@mui/icons-material/Hub';

const icons = {
    IconList,
    IconUser
};

const pages = {
    id: 'main',
    type: 'group',
    children: [
        {
            id: 'asset',
            title: 'ASSETS',
            type: 'item',
            url: '/assets',
            icon: icons.IconList,
            breadcrumbs: false,
            role: ['User', 'Admin']
        },
        {
            id: 'halog',
            title: 'DEVICES',
            type: 'item',
            url: '/halogs',
            icon: () => '',
            breadcrumbs: false,
            role: ['Sys_Admin']
        },
        {
            id: 'user',
            title: 'USERS',
            type: 'item',
            url: '/users',
            icon: icons.IconUser,
            breadcrumbs: false,
            role: ['Admin']
        },
        {
            id: 'gateway',
            title: 'Gateways',
            type: 'item',
            url: '/gateways',
            icon: HubIcon,
            breadcrumbs: false,
            role: ['Sys_Admin']
        }
    ]
};

export default pages;
