import Asset from 'types/asset';
import AssetFilter from 'types/filters/assetFilter';
import GatewayFilter from 'types/filters/gatewayFilter';
import Gateway from 'types/gateway';
import { PaginationResult } from 'types/paginationResult';
import axios from 'utils/axios';

class GatewayService {
    async getGateways(gatewayFilter: GatewayFilter): Promise<PaginationResult<Gateway>> {
        try {
            const body = {
                page: gatewayFilter.page,
                recordsPerPage: gatewayFilter.recordsPerPage,
                searchString: gatewayFilter.searchString,
                orderBy: gatewayFilter.orderBy,
                orderAscending: gatewayFilter.orderAscending
            };
            const result = await axios.post<PaginationResult<Gateway>>(`/GetGateways`, body);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch gateway list');
        }
    }

    async addGateway(gateway: Gateway): Promise<boolean> {
        try {
            const response = await axios.post(`/AddGateway`, {
                name: gateway.name,
                maintenanceMode: gateway.maintenanceMode
            });
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to add gateway');
        }
    }

    async editGateway(gateway: Gateway): Promise<boolean> {
        try {
            const response = await axios.post(`/EditGateway`, gateway);
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to edit gateway');
        }
    }

    async deleteGateway(gatewayId: string): Promise<boolean> {
        try {
            const response = await axios.post(`/DeleteGateway`, gatewayId);
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to delete gateway');
        }
    }
}

export default new GatewayService();
