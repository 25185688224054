// routing
import Routes from 'routes';
// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import ThemeCustomization from 'themes';
import 'index.css';

// auth provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { AuthProvider } from 'contexts/AuthContext';
import { PowerbiProvider } from 'contexts/PowerbiContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/it';
import useConfig from 'hooks/useConfig';
import Snackbar from 'ui-component/extended/Snackbar';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => {
    const { locale } = useConfig();
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <ThemeCustomization>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <PowerbiProvider>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </PowerbiProvider>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </ThemeCustomization>
        </LocalizationProvider>
    );
};

export default App;
