// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';

// import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import SkeletonBody from 'ui-component/skeleton/SkeletonBody';
import { IconClipboardText, IconCalendarTime, IconBluetooth } from '@tabler/icons';
import Halog from 'types/halog';
import useConfig from 'hooks/useConfig';
import Utilities from 'utils/utilities';
import darkThemeIcon from 'assets/images/halog-icon-dark-theme.png';
import lightThemeIcon from 'assets/images/halog-icon-light-theme.png';

interface HalogListMobileProps {
    halogList: Halog[] | undefined;
    onEdit: (halog: Halog) => void;
}

const HalogListMobile: FC<HalogListMobileProps> = (props) => {
    const { halogList, onEdit } = props;
    const { t } = useTranslation();
    const [rows, setRows] = useState<Halog[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const theme = useTheme();
    const { locale } = useConfig();
    const utilities = new Utilities();

    useEffect(() => {
        (async () => {
            if (halogList) {
                setRows(halogList);
            }
        })();
    }, [halogList]);

    return (
        <Grid aria-labelledby="tableTitle">
            {!isLoading ? (
                <Grid>
                    {rows.map((row, index) => {
                        if (typeof row === 'number') return null;

                        return (
                            <Grid
                                key={row.id}
                                sx={{
                                    display: 'block',
                                    '& .MuiGrid-root': { paddingRight: 0 }
                                }}
                            >
                                <Grid container spacing={1} sx={{ mt: 2, ml: 0, pl: 2 }}>
                                    <Grid item xs={12} sx={{ pl: 20 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    {theme.palette.mode === 'dark' ? (
                                                        <img src={darkThemeIcon} style={{ width: '24px', height: '24px' }} alt="" />
                                                    ) : (
                                                        <img src={lightThemeIcon} style={{ width: '24px', height: '24px' }} alt="" />
                                                    )}
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('DEVICE_NAME')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {row.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pl: 20 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <IconClipboardText />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('ASSET_NAME')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {row.assetName ? row.assetName : '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pl: 20 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <IconBluetooth />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('PAIRED_STATUS')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {row.pairDate && (
                                                            <BluetoothConnectedIcon sx={{ fontSize: '1.3rem' }} color="secondary" />
                                                        )}
                                                        {!row.pairDate && (
                                                            <BluetoothDisabledIcon sx={{ fontSize: '1.3rem' }} color="error" />
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pl: 10, pr: 5 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <IconCalendarTime />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('PAIRED_DATE')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {row.pairDate ? utilities.formatDate(row.pairDate, locale) : '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider sx={{ mx: 3 }} />
                                        <Grid
                                            container
                                            sx={{
                                                verticalAlign: 'bottom',
                                                textAlign: 'center',
                                                mt: 0.5
                                            }}
                                        >
                                            <Grid item xs={12} sx={{ mb: 1 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        color: 'secondary'
                                                    }}
                                                >
                                                    <IconButton color="secondary" size="large" onClick={() => onEdit(row)}>
                                                        <EditTwoToneIcon sx={{ fontSize: '1.5rem' }} />
                                                    </IconButton>
                                                    {t('EDIT')}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mx: 1 }} />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <SkeletonBody numHeaders={1} showAction />
            )}
        </Grid>
    );
};

export default HalogListMobile;
