import { FC, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConfirmatioDialog from 'ui-component/ConfirmationDialog';
import Gateway from 'types/gateway';

interface ChildComponentProps {
    gateway?: Gateway;
    onSave(gateway: Gateway): any;
    onDelete(gateway: Gateway): any;
    onClose(): any;
}

const GatewayEdit: FC<ChildComponentProps> = (props) => {
    const { gateway, onSave, onDelete, onClose } = props;
    const [open, setOpen] = useState(false);
    const [currentGateway, setCurrentGateway] = useState<Gateway | undefined>(gateway);
    const theme = useTheme();
    const { t } = useTranslation();
    const [dialogDelete, setDialogDelete] = useState(false);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (gateway === undefined) setOpen(false);
        else {
            setOpen(true);
            setCurrentGateway(gateway);
        }
    }, [gateway]);

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t('EDIT')} gateway {gateway?.name}
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important', width: mobileDevice ? window.innerWidth - 65 : 500 }}>
                    <Grid container>
                        <Grid item md={8} xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <InputLabel> {t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={currentGateway?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentGateway!,
                                            name: d.target.value
                                        };
                                        setCurrentGateway(e);
                                    }}
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12} sx={{ mb: 2, textAlign: 'center' }}>
                            <FormControl fullWidth required>
                                <>{t('MAINTENANCE_MODE')}</>
                                <Checkbox
                                    checked={currentGateway?.maintenanceMode!}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentGateway!,
                                            maintenanceMode: d.target.checked
                                        };
                                        setCurrentGateway(e);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Typography marginTop="5px"> * {t('MANDATORY_FIELDS')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        sx={{ alignItems: 'center', justifyContent: 'space-between', paddingLeft: '15px', paddingRight: '15px' }}
                    >
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: theme.palette.error.main,
                                    borderRadius: 7,
                                    border: '2px solid',
                                    borderColor: theme.palette.error.main
                                }}
                                onClick={() => setDialogDelete(true)}
                            >
                                <Typography sx={{ color: 'white' }}> {t('DELETE')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClose}
                                autoFocus
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.secondary.main,
                                    borderRadius: 7,
                                    marginRight: 10
                                }}
                            >
                                <Typography sx={{ color: theme.palette.primary.main }}> {t('CANCEL')}</Typography>
                            </Button>
                            <Button
                                onClick={() => onSave(currentGateway!)}
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.primary.main,
                                    borderRadius: 7,
                                    background: theme.palette.primary.main
                                }}
                            >
                                <Typography sx={{ color: '#fff' }}> {t('SAVE')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <ConfirmatioDialog
                isVisible={dialogDelete}
                onClose={() => {
                    setDialogDelete(false);
                }}
                onConfirm={() => {
                    setDialogDelete(false);
                    onDelete(gateway!);
                }}
            />
        </>
    );
};

export default GatewayEdit;
