export default class Utilities {
    formatMacAddress(macString: string): string {
        const formattedMac = macString.match(/.{1,2}/g)?.join(':');
        return formattedMac!;
    }

    formatDate(dateString: string, locale: string): string {
        const date = new Date(dateString);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        if (locale === 'it') {
            return `${day}-${month}-${year} ${hours}:${minutes}`;
        }
        let hh = date.getHours();
        const min = `0 ${minutes}`.slice(-2);
        const ampm = hh >= 12 ? 'PM' : 'AM';
        hh %= 12;
        hh = hh === 0 ? 12 : hh;
        const hourStr = `0 ${hh}`.slice(-2);
        return `${month}-${day}-${year} ${hourStr}:${min} ${ampm}`;
    }

    getFormValue = (e: any) => {
        const name = e.target.name;
        let value = '';

        if (e.target.type === 'checkbox') {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        return { name, value };
    };

    getMonth = (date: Date | null) => {
        if (date) {
            const month = date.getMonth();
            switch (month) {
                case 0:
                    return 'JANUARY';
                case 1:
                    return 'FEBRUARY';
                case 2:
                    return 'MARCH';
                case 3:
                    return 'APRIL';
                case 4:
                    return 'MAY';
                case 5:
                    return 'JUNE';
                case 6:
                    return 'JULY';
                case 7:
                    return 'AUGUST';
                case 8:
                    return 'SEPTEMBER';
                case 9:
                    return 'OCTOBER';
                case 10:
                    return 'NOVEMBER';
                case 11:
                    return 'DECEMBER';
                default:
                    return 'MONTH_NOT_VALID';
            }
        }
        return '';
    };

    isNotNullOrEmpty = (value: string) => {
        if (value !== undefined && value.trim() !== '') {
            return true;
        }
        return false;
    };
}
