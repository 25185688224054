import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import AssetList from 'views/pages/assets/assetsList';
import HalogList from 'views/pages/halog/halogList';
import NotFoundError from 'views/pages/maintenance/NotFoundError';
import UserList from 'views/pages/users/userList';
import Dashboard from 'views/dashboard/Dashboard';
import GatewayList from 'views/pages/gateways/gatewayList';
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/assets',
            element: <AssetList />,
            roles: ['Admin', 'User']
        },
        {
            path: '/halogs',
            element: <HalogList />,
            roles: ['Sys_Admin']
        },
        {
            path: '/users',
            element: <UserList />,
            roles: ['Admin']
        },
        {
            path: '/gateways',
            element: <GatewayList />,
            roles: ['Sys_Admin']
        },
        {
            path: '/not_found',
            element: <NotFoundError />,
            roles: ['All']
        },
        {
            path: '/dashboard',
            element: <Dashboard />,
            roles: ['User']
        },
        {
            path: '*',
            element: <NotFoundError />,
            roles: ['All']
        }
    ]
};

export default MainRoutes;
