import Halog from 'types/halog';
import { FC, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConfirmatioDialog from 'ui-component/ConfirmationDialog';

interface ChildComponentProps {
    halog?: Halog;
    onSave(halog: Halog): any;
    onDelete(halog: Halog): any;
    onClose(): any;
}

const HalogEdit: FC<ChildComponentProps> = (props) => {
    const { halog, onSave, onDelete, onClose } = props;
    const [open, setOpen] = useState(false);
    const [halogCopy, setHalogCopy] = useState(halog);
    const theme = useTheme();
    const { t } = useTranslation();
    const [dialogDelete, setDialogDelete] = useState(false);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (halog === undefined) setOpen(false);
        else {
            setOpen(true);
            setHalogCopy(halog);
        }
    }, [halog]);

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t('EDIT_DEVICE')} {halog?.name}
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important', width: mobileDevice ? window.innerWidth - 65 : 500 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel> {t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={halog?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...halogCopy!,
                                            name: d.target.value
                                        };
                                        setHalogCopy(e);
                                    }}
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        sx={{ alignItems: 'center', justifyContent: 'space-between', paddingLeft: '15px', paddingRight: '15px' }}
                    >
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: theme.palette.error.main,
                                    borderRadius: 7,
                                    border: '2px solid',
                                    borderColor: theme.palette.error.main
                                }}
                                onClick={() => setDialogDelete(true)}
                            >
                                <Typography sx={{ color: 'white' }}> {t('DELETE')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClose}
                                autoFocus
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.secondary.main,
                                    borderRadius: 7,
                                    marginRight: 10
                                }}
                            >
                                <Typography sx={{ color: theme.palette.primary.main }}> {t('CANCEL')}</Typography>
                            </Button>
                            <Button
                                onClick={() => onSave(halogCopy!)}
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.primary.main,
                                    borderRadius: 7,
                                    background: theme.palette.primary.main
                                }}
                            >
                                <Typography sx={{ color: '#fff' }}> {t('SAVE')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <ConfirmatioDialog
                isVisible={dialogDelete}
                onClose={() => {
                    setDialogDelete(false);
                }}
                onConfirm={() => {
                    setDialogDelete(false);
                    onDelete(halog!);
                }}
            />
        </>
    );
};

export default HalogEdit;
