import User from 'types/user';
import { FC, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import roleList from 'types/roles';
import Utilities from 'utils/utilities';
import SelectOption from 'types/selectOption';
import useAuth from 'hooks/useAuth';

interface ChildComponentProps {
    user?: User;
    onAdd(user: User): any;
    onClose(): any;
}

const UserAdd: FC<ChildComponentProps> = (props) => {
    const { user, onAdd, onClose } = props;
    const [open, setOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(user);
    const { user: loggedUser } = useAuth();
    const { t } = useTranslation();
    const theme = useTheme();
    const roles: SelectOption[] = roleList(t);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    const utilities = new Utilities();

    useEffect(() => {
        if (user === undefined) setOpen(false);
        else {
            const userCopy = {
                ...user!,
                role: 'User'
            };
            setCurrentUser(userCopy);
            setOpen(true);
        }
    }, [user]);

    const handleRole = (e: any) => {
        const { value } = utilities.getFormValue(e);
        let userCopy = null;

        if (value === 'System_Admin') {
            userCopy = {
                ...currentUser!,
                isSystemAdmin: true,
                role: 'System_Admin'
            };
        } else {
            userCopy = {
                ...currentUser!,
                role: value,
                isSystemAdmin: false
            };
        }
        setCurrentUser(userCopy);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title"> {t('ADD_NEW_USER')} </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important', width: mobileDevice ? window.innerWidth - 65 : 500 }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: 2, mt: 1 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={user?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentUser!,
                                            name: d.target.value
                                        };
                                        setCurrentUser(e);
                                    }}
                                    type="text"
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('SURNAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={user?.surname}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentUser!,
                                            surname: d.target.value
                                        };
                                        setCurrentUser(e);
                                    }}
                                    type="text"
                                    label={t('SURNAME')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth required>
                                <InputLabel> {t('Email')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={user?.username}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentUser!,
                                            username: d.target.value
                                        };
                                        setCurrentUser(e);
                                    }}
                                    type="text"
                                    label={t('Email')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('COMPANY')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={user?.company}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentUser!,
                                            company: d.target.value
                                        };
                                        setCurrentUser(e);
                                    }}
                                    type="text"
                                    label="Company"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('COMPANY_EMAIL')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={user?.emailCompany}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentUser!,
                                            emailCompany: d.target.value
                                        };
                                        setCurrentUser(e);
                                    }}
                                    type="text"
                                    label={t('COMPANY_EMAIL')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('ROLE')}</InputLabel>
                                <Select
                                    label={t('ROLE')}
                                    name="role"
                                    onChange={handleRole}
                                    sx={{ color: 'white' }}
                                    value={currentUser?.role}
                                    defaultValue="User"
                                >
                                    {roles.map((role: any) => {
                                        if (role.value === 'System_Admin' && !loggedUser?.isSystemAdmin) {
                                            return null;
                                        }
                                        return (
                                            <MenuItem value={role.value} key={role.value}>
                                                {t(role.label)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Typography marginTop="5px"> * {t('MANDATORY_FIELDS')}</Typography>
                </DialogContent>
                <DialogActions sx={{ marginRight: '16px' }}>
                    <Button
                        onClick={() => {
                            setCurrentUser(undefined);
                            onClose();
                        }}
                        autoFocus
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.secondary.main,
                            borderRadius: 7
                        }}
                    >
                        <Typography sx={{ color: theme.palette.primary.main }}> {t('CANCEL')}</Typography>
                    </Button>
                    <Button
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.primary.main,
                            borderRadius: 7,
                            background: theme.palette.primary.main
                        }}
                        onClick={() => {
                            onAdd(currentUser!);
                        }}
                    >
                        <Typography style={{ color: '#fff' }}>{t('ADD')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserAdd;
