import Asset from 'types/asset';
import { FC, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SelectOption from 'types/selectOption';
import Utilities from 'utils/utilities';
import frequencyConfList from 'types/frequencyConf';
import accelerationConf from 'types/accelerationConf';
import ConfirmatioDialog from 'ui-component/ConfirmationDialog';

interface ChildComponentProps {
    asset?: Asset;
    onSave(asset: Asset): any;
    onDelete(asset: Asset): any;
    onClose(): any;
}

const AssetEdit: FC<ChildComponentProps> = (props) => {
    const { asset, onSave, onDelete, onClose } = props;
    const [open, setOpen] = useState(false);
    const [currentAsset, setCurrentAsset] = useState<Asset | undefined>(asset);
    const theme = useTheme();
    const { t } = useTranslation();
    const [dialogDelete, setDialogDelete] = useState(false);
    const utilities = new Utilities();
    const frequencyConf: SelectOption[] = frequencyConfList(t);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (asset === undefined) setOpen(false);
        else {
            setOpen(true);
            setCurrentAsset(asset);
        }
    }, [asset]);

    const handleFrequency = (e: any) => {
        const { value } = utilities.getFormValue(e);
        const assetCopy = {
            ...currentAsset!,
            frequency: parseFloat(value)
        };
        setCurrentAsset(assetCopy);
    };

    const handleAcceleration = (e: any) => {
        const { value } = utilities.getFormValue(e);
        const assetCopy = {
            ...currentAsset!,
            acceleration: parseFloat(value)
        };
        setCurrentAsset(assetCopy);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t('EDIT')} asset {asset?.name}
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important', width: mobileDevice ? window.innerWidth - 65 : 500 }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <InputLabel> {t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={asset?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentAsset!,
                                            name: d.target.value
                                        };
                                        setCurrentAsset(e);
                                    }}
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('FREQUENCY')}</InputLabel>
                                <Select
                                    label={t('FREQUENCY')}
                                    name="frequency"
                                    onChange={handleFrequency}
                                    sx={{ color: 'white' }}
                                    value={Math.floor(currentAsset?.frequency!)}
                                >
                                    {frequencyConf.map((frequency) => (
                                        <MenuItem value={frequency.value} key={frequency.value}>
                                            {t(frequency.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('ACCELERATION')}</InputLabel>
                                <Select
                                    label={t('ACCELERATION')}
                                    name="acceleration"
                                    onChange={handleAcceleration}
                                    sx={{ color: 'white' }}
                                    value={Math.floor(currentAsset?.acceleration!)}
                                >
                                    {accelerationConf.map((acceleration) => (
                                        <MenuItem value={acceleration.value} key={acceleration.value}>
                                            {t(acceleration.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Typography marginTop="5px"> * {t('MANDATORY_FIELDS')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        sx={{ alignItems: 'center', justifyContent: 'space-between', paddingLeft: '15px', paddingRight: '15px' }}
                    >
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: theme.palette.error.main,
                                    borderRadius: 7,
                                    border: '2px solid',
                                    borderColor: theme.palette.error.main
                                }}
                                onClick={() => setDialogDelete(true)}
                            >
                                <Typography sx={{ color: 'white' }}> {t('DELETE')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClose}
                                autoFocus
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.secondary.main,
                                    borderRadius: 7,
                                    marginRight: 10
                                }}
                            >
                                <Typography sx={{ color: theme.palette.primary.main }}> {t('CANCEL')}</Typography>
                            </Button>
                            <Button
                                onClick={() => onSave(currentAsset!)}
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.primary.main,
                                    borderRadius: 7,
                                    background: theme.palette.primary.main
                                }}
                            >
                                <Typography sx={{ color: '#fff' }}> {t('SAVE')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <ConfirmatioDialog
                isVisible={dialogDelete}
                onClose={() => {
                    setDialogDelete(false);
                }}
                onConfirm={() => {
                    setDialogDelete(false);
                    onDelete(asset!);
                }}
            />
        </>
    );
};

export default AssetEdit;
