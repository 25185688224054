import { FC, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import User from 'types/user';
import Utilities from 'utils/utilities';
import SelectOption from 'types/selectOption';
import roleList from 'types/roles';
import useAuth from 'hooks/useAuth';
import ConfirmatioDialog from 'ui-component/ConfirmationDialog';

interface ChildComponentProps {
    user?: User;
    onSave(user: User): any;
    onDelete(user: User): any;
    onClose(): any;
}

const UserEdit: FC<ChildComponentProps> = (props) => {
    const { user, onSave, onDelete, onClose } = props;
    const [open, setOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(user);
    const { user: loggedUser } = useAuth();
    const theme = useTheme();
    const { t } = useTranslation();
    const [dialogDelete, setDialogDelete] = useState(false);
    const roles: SelectOption[] = roleList(t);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    const utilities = new Utilities();

    useEffect(() => {
        if (user === undefined) setOpen(false);
        else {
            let defaultRole: string;
            if (user?.isSystemAdmin) {
                defaultRole = 'System_Admin';
            } else {
                defaultRole = user.role!;
            }
            const userCopy = {
                ...user!,
                role: defaultRole
            };
            setCurrentUser(userCopy);
            setOpen(true);
        }
    }, [user]);

    const handleRole = (e: any) => {
        const { value } = utilities.getFormValue(e);
        let userCopy = null;

        if (value === 'System_Admin') {
            userCopy = {
                ...currentUser!,
                isSystemAdmin: true,
                role: ''
            };
        } else {
            userCopy = {
                ...currentUser!,
                role: value,
                isSystemAdmin: false
            };
        }
        setCurrentUser(userCopy);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t('EDIT_USER')} {user?.username}
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important', width: mobileDevice ? window.innerWidth - 65 : 500 }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: 2, mt: 1 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={user?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentUser!,
                                            name: d.target.value
                                        };
                                        setCurrentUser(e);
                                    }}
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('SURNAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={user?.surname}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentUser!,
                                            surname: d.target.value
                                        };
                                        setCurrentUser(e);
                                    }}
                                    label={t('SURNAME')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth required>
                                <InputLabel> {t('Email')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={user?.username}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentUser!,
                                            username: d.target.value
                                        };
                                        setCurrentUser(e);
                                    }}
                                    label={t('Email')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('COMPANY')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={user?.company}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentUser!,
                                            company: d.target.value
                                        };
                                        setCurrentUser(e);
                                    }}
                                    type="text"
                                    label={t('COMPANY')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel>{t('COMPANY_EMAIL')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={user?.emailCompany}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentUser!,
                                            emailCompany: d.target.value
                                        };
                                        setCurrentUser(e);
                                    }}
                                    type="text"
                                    label={t('COMPANY_EMAIL')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel>{t('ROLE')}</InputLabel>
                                <Select
                                    label={t('ROLE')}
                                    name="frequency"
                                    onChange={handleRole}
                                    sx={{ color: 'white' }}
                                    value={currentUser?.role}
                                    defaultValue={user?.role}
                                    disabled={loggedUser?.isSystemAdmin && user?.isSystemAdmin}
                                >
                                    {roles.map((role: any) => {
                                        if (role.value === 'System_Admin' && !currentUser?.isSystemAdmin) {
                                            return null;
                                        }
                                        return (
                                            <MenuItem value={role.value} key={role.value}>
                                                {t(role.label)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Typography marginTop="5px"> * {t('MANDATORY_FIELDS')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        sx={{ alignItems: 'center', justifyContent: 'space-between', paddingLeft: '15px', paddingRight: '15px' }}
                    >
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: theme.palette.error.main,
                                    borderRadius: 7,
                                    border: '2px solid',
                                    borderColor: theme.palette.error.main
                                }}
                                onClick={() => setDialogDelete(true)}
                            >
                                <Typography sx={{ color: 'white' }}> {t('DELETE')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClose}
                                autoFocus
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.secondary.main,
                                    borderRadius: 7,
                                    marginRight: 10
                                }}
                            >
                                <Typography sx={{ color: theme.palette.primary.main }}> {t('CANCEL')}</Typography>
                            </Button>
                            <Button
                                onClick={() => onSave(currentUser!)}
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.primary.main,
                                    borderRadius: 7,
                                    background: theme.palette.primary.main
                                }}
                            >
                                <Typography sx={{ color: '#fff' }}> {t('SAVE')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <ConfirmatioDialog
                isVisible={dialogDelete}
                onClose={() => {
                    setDialogDelete(false);
                }}
                onConfirm={() => {
                    setDialogDelete(false);
                    onDelete(user!);
                }}
            />
        </>
    );
};

export default UserEdit;
