import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { useEffect } from 'react';
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import useAuth from 'hooks/useAuth';

// ==============================|| ROUTING RENDER ||============================== //

const Routes = () => {
    const navigate = useNavigate();
    const { user } = useAuth();

    const location = useSelector<AppState, any>(
        (state) => state.router.location,
        (left, right) => JSON.stringify(left) === JSON.stringify(right)
    );

    const url = location.pathname.substring(0, 9);
    const isCallbackUrl = url === '/callback';

    useEffect(() => {
        if (isCallbackUrl) {
            navigate('/callback', { replace: true });
        }
    }, [isCallbackUrl]);

    const RoleBasedRoute = ({ element, allowedRoles }: { element: JSX.Element; allowedRoles: string[] }) => {
        if (user?.isSystemAdmin) {
            return element;
        }
        if (!allowedRoles.includes(user?.role!) && allowedRoles[0] !== 'All') {
            return <Navigate to="/not_found" />;
        }
        return element;
    };

    const UpdatedMainRoutes = {
        ...MainRoutes,
        children: MainRoutes.children.map((route) => ({
            ...route,
            element: <RoleBasedRoute element={route.element} allowedRoles={route.roles} />
        }))
    };

    return useRoutes([LoginRoutes, AuthenticationRoutes, UpdatedMainRoutes]);
};

export default Routes;
