import Asset from 'types/asset';
import { FC, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Utilities from 'utils/utilities';
import SelectOption from 'types/selectOption';
import accelerationConf from 'types/accelerationConf';
import frequencyConfList from 'types/frequencyConf';

interface ChildComponentProps {
    asset?: Asset;
    onAdd(asset: Asset): any;
    onClose(): any;
}

const AssetAdd: FC<ChildComponentProps> = (props) => {
    const { asset, onAdd, onClose } = props;
    const [open, setOpen] = useState(false);
    const [currentAsset, setCurrentAsset] = useState(asset);
    const { t } = useTranslation();
    const utilities = new Utilities();
    const theme = useTheme();
    const frequencyConf: SelectOption[] = frequencyConfList(t);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (asset === undefined) {
            setOpen(false);
        } else {
            const assetCopy = {
                ...currentAsset!,
                acceleration: 2,
                frequency: 2
            };
            setCurrentAsset(assetCopy);
            setOpen(true);
        }
    }, [asset]);

    const handleFrequency = (e: any) => {
        const { value } = utilities.getFormValue(e);
        const assetCopy = {
            ...currentAsset!,
            frequency: parseFloat(value)
        };
        setCurrentAsset(assetCopy);
    };

    const handleAcceleration = (e: any) => {
        const { value } = utilities.getFormValue(e);
        const assetCopy = {
            ...currentAsset!,
            acceleration: parseFloat(value)
        };
        setCurrentAsset(assetCopy);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title"> {t('ADD_NEW_ASSET')} </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important', width: mobileDevice ? window.innerWidth - 65 : 500 }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth required>
                                <InputLabel>{t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={asset?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentAsset!,
                                            name: d.target.value
                                        };
                                        setCurrentAsset(e);
                                    }}
                                    type="text"
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel>{t('FREQUENCY')}</InputLabel>
                                <Select
                                    label={t('FREQUENCY')}
                                    name="frequency"
                                    onChange={handleFrequency}
                                    sx={{ color: 'white' }}
                                    value={Math.floor(currentAsset?.frequency!)}
                                    defaultValue={2}
                                >
                                    {frequencyConf.map((frequency) => (
                                        <MenuItem value={frequency.value} key={frequency.value}>
                                            {t(frequency.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel>{t('ACCELERATION')}</InputLabel>
                                <Select
                                    label={t('ACCELERATION')}
                                    name="acceleration"
                                    onChange={handleAcceleration}
                                    sx={{ color: 'white' }}
                                    value={Math.floor(currentAsset?.acceleration!)}
                                    defaultValue={2}
                                >
                                    {accelerationConf.map((acceleration) => (
                                        <MenuItem value={acceleration.value} key={acceleration.value}>
                                            {t(acceleration.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Typography marginTop="5px"> * {t('MANDATORY_FIELDS')}</Typography>
                </DialogContent>
                <DialogActions sx={{ marginRight: '16px' }}>
                    <Button
                        onClick={() => {
                            setCurrentAsset(undefined);
                            onClose();
                        }}
                        autoFocus
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.secondary.main,
                            borderRadius: 7
                        }}
                    >
                        <Typography sx={{ color: theme.palette.primary.main }}> {t('CANCEL')}</Typography>
                    </Button>
                    <Button
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.primary.main,
                            borderRadius: 7,
                            background: theme.palette.primary.main
                        }}
                        onClick={() => {
                            onAdd(currentAsset!);
                        }}
                    >
                        <Typography style={{ color: '#fff' }}>{t('ADD')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AssetAdd;
