import UserFilter from 'types/filters/userFilter';
import { PaginationResult } from 'types/paginationResult';
import User from 'types/user';
import { UserInfo } from 'types/userInfo';
import axios from 'utils/axios';

class UserService {
    async getUserInfo(): Promise<UserInfo> {
        try {
            const res = await axios.post<UserInfo>(`/GetUserInfo`);
            return res.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch user info');
        }
    }

    async setTenant(tenantId: string): Promise<UserInfo> {
        try {
            const res = await axios.post<UserInfo>(`/SetTenant`, { TenantId: tenantId });

            return res.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to set new tenant');
        }
    }

    async setUserConfig(tenantId: string, preferredLanguage: string, useDarkTheme: boolean): Promise<boolean> {
        try {
            const result = await axios.post(`/SetUserConfig`, {
                TenantId: tenantId,
                PreferredLanguage: preferredLanguage,
                UseDarkTheme: useDarkTheme
            });
            if (result.status >= 200 && result.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to set user config');
        }
    }

    async getUsers(userFilter: UserFilter): Promise<PaginationResult<User>> {
        try {
            const result = await axios.post<PaginationResult<User>>(`/GetUsers`, userFilter);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch user list');
        }
    }

    async addUser(user: User): Promise<boolean> {
        const response = await axios.post(`/AddUser`, user);
        if (response.status >= 200 && response.status < 300) {
            return true;
        }
        return false;
    }

    async editUser(user: User): Promise<boolean> {
        try {
            const response = await axios.post(`/EditUser`, user);
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to edit user');
        }
    }

    async deleteUser(userId: string, tenantId: string): Promise<boolean> {
        try {
            const body = {
                userId: userId,
                tenantId: tenantId
            };
            const response = await axios.post(`/DeleteUser`, body);
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to delete user');
        }
    }
}
export default new UserService();
